import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import { Snackbar } from '@mui/material';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  @media (max-width: 960px) {
    padding: 0px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1350px;
  padding: 0px 0px 80px 0px;
  gap: 12px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 16px;
  }
`;

const ContactForm = styled.form`
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card};
  padding: 32px;
  border-radius: 16px;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
`;

const ContactTitle = styled.div`
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`;

const ContactInput = styled.input`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

const ContactInputMessage = styled.textarea`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

const ContactButton = styled.input`
  width: 100%;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  background: hsla(271, 100%, 50%, 1);
  background: linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  padding: 13px 16px;
  margin-top: 2px;
  border-radius: 12px;
  border: none;
  color: ${({ theme }) => theme.text_primary};
  font-size: 18px;
  font-weight: 600;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 14px;
  margin-top: -8px;
`;

const Contact = () => {
  const [formData, setFormData] = useState({
    from_email: '',
    from_name: '',
    subject: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const form = useRef();

  const validate = () => {
    const newErrors = {};
    if (!formData.from_email || !/\S+@\S+\.\S+/.test(formData.from_email)) {
      newErrors.from_email = 'Please enter a valid email.';
    }
    if (!formData.from_name) {
      newErrors.from_name = 'Name is required.';
    }
    if (!formData.subject) {
      newErrors.subject = 'Subject is required.';
    }
    if (!formData.message) {
      newErrors.message = 'Message is required.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) return;

    emailjs.sendForm('service_1q41bxo', 'template_3v3j7kq', form.current, '9leMhy8A5M233o4h6')
      .then(() => {
        setOpen(true);
        form.current.reset();
        setFormData({
          from_email: '',
          from_name: '',
          subject: '',
          message: '',
        });
      })
      .catch((error) => {
        console.error(error.text);
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the form data
    setFormData({ ...formData, [name]: value });

    // Clear the error for this field if valid
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (name === "from_email" && /\S+@\S+\.\S+/.test(value)) {
        delete newErrors.from_email;
      }
      if (name === "from_name" && value.trim() !== "") {
        delete newErrors.from_name;
      }
      if (name === "subject" && value.trim() !== "") {
        delete newErrors.subject;
      }
      if (name === "message" && value.trim() !== "") {
        delete newErrors.message;
      }
      return newErrors;
    });
  };
  return (
    <Container>
      <Wrapper>
        <Title>Contact</Title>
        <Desc>Feel free to reach out to me for any questions or opportunities!</Desc>
        <ContactForm ref={form} onSubmit={handleSubmit}>
          <ContactTitle>Email Me 🚀</ContactTitle>
          <ContactInput
            placeholder="Your Email"
            name="from_email"
            value={formData.from_email}
            onChange={handleChange}
          />
          {errors.from_email && <ErrorText>{errors.from_email}</ErrorText>}
          <ContactInput
            placeholder="Your Name"
            name="from_name"
            value={formData.from_name}
            onChange={handleChange}
          />
          {errors.from_name && <ErrorText>{errors.from_name}</ErrorText>}
          <ContactInput
            placeholder="Subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
          />
          {errors.subject && <ErrorText>{errors.subject}</ErrorText>}
          <ContactInputMessage
            placeholder="Message"
            rows="4"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
          {errors.message && <ErrorText>{errors.message}</ErrorText>}
          <ContactButton type="submit" value="Send" />
        </ContactForm>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
          message="Email sent successfully!"
        />
      </Wrapper>
    </Container>
  );
};

export default Contact;
