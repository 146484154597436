export const Bio = {
  name: "Sirancheevi Palraj",
  roles: [
    "Full Stack Developer.",
    "Blockchain Enthusiast.",
    "Freelancer.",
    "Mentor.",
    "Techie.",
  ],
  description:
    "As a full-stack web developer with a passion for blockchain and finance, I leverage my diverse expertise to craft innovative solutions. With a background in mentorship and multiple project experiences, I bring versatility and a commitment to excellence to every endeavor. Continuously seeking growth and learning, I aim to make a positive impact in the digital realm through my creative contributions.!",
  github: "https://github.com/Sirancheevi-Palraj",
  certificates: "https://drive.google.com/drive/u/0/folders/1bzgISb6A6uobMNJaCJHu0UYeGjMQ3H06",
  resume:
    "https://drive.google.com/file/d/1wgZP5NB94E6H-H-FgAgJvmzviu6X8nsR/view",
  linkedin: "www.linkedin.com/in/sirancheevipalraj",
  twitter: "https://twitter.com/SirancheeviP",
  insta: "https://www.instagram.com/sirancheevipalraj/",
  facebook: "https://www.facebook.com/viragai.sirancheevi",
  mail: "mailto:sirancheevi.palraj@gmail.com",
};

export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "React JS",
        image:
          "https://firebasestorage.googleapis.com/v0/b/naveen-portfolio-images.appspot.com/o/Icons%2Fscience.png?alt=media&token=116f4e6f-ad93-4bf1-a69a-9c337010c33b&_gl=1*sa95ma*_ga*ODM3NjUxNDgyLjE2OTg4MjU1NTY.*_ga_CW55HF8NVT*MTY5ODgyOTQzNi4yLjEuMTY5ODgzMzU5Ny42MC4wLjA.",
      },
     
      {
        name: "JavaScript",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2Fjava-script.png?alt=media&token=9b2ffc2d-baa7-42c9-8ff2-4cb7be71b733",
      },
      {
        name: "jQuery",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2Fjquery.png?alt=media&token=ca883f27-cd20-45f0-8063-6dadedcb693c",
      },
      {
        name: "Web3 JS",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2Fweb3.png?alt=media&token=7a378dcb-577d-4979-b3d1-18c08752487f",
      },
      {
        name: "Blazor",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FBlazor.png?alt=media&token=dbb01579-5bea-445d-95bc-c2c1ba477700",
      },
      {
        name: "HTML",
        image: "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2Fhtml.png?alt=media&token=c587e585-bff4-423d-8124-e323d92c7dcc",
      },
      {
        name: "CSS",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2Fcss-3.png?alt=media&token=26039f12-f30e-4464-a03d-724098eb0186",
      },
      
      {
        name: "Bootstrap",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2Fbootstrap.png?alt=media&token=9c32b4c7-ed15-4c8c-8e1d-50914227bcb8",
      },
      

    ],
  },
  {
    title: "Backend",
    skills: [
      
      {
        name: "Asp .Net Core",
        image: "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2Fdotnetcore.png?alt=media&token=1e2a8319-4e5a-44e7-930f-3520f9477646",
      },
      {
        name: "Asp .Net MVC",
        image: "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2Fdotnetcore.png?alt=media&token=1e2a8319-4e5a-44e7-930f-3520f9477646",
      },
      {
        name: "Windows Service",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FWindowsService.png?alt=media&token=640d98f2-4241-44de-aa06-4849f7ce5b01",
      },
      {
        name: "Docker",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FDocker.png?alt=media&token=857ecca1-550e-4e1f-b1fd-9f7df66df76e",
      },
      
      
      {
        name: "Numpy",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FNumpy.png?alt=media&token=f06ed9c7-4c05-4513-b2de-fcf2d638d126",
      },
      {
        name: "Pandas",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FPythonPands.jpeg?alt=media&token=222ddb71-4821-4e7c-9f8a-749d0fdcdcda",
      },
      {
        name: "matplotlib",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FMatplot.png?alt=media&token=efe0fc54-2837-43a4-9679-a700bf740d1f",
      },
      
      
    ],
  },
  {
    title: "Blockchain",
    skills: [
      
      
      {
        name: "Ganache",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FGanache.png?alt=media&token=38b63b4a-8056-4032-9bd1-ef00709ae6a8",
      },
      {
        name: "Metamask",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXNySyTr5jtlW3ygjr4gmbpXm6nfNY-3imb8rmt8bocQ&s",
      },
      {
        name: "Hyperledger",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FHyperLedger.png?alt=media&token=84f0790a-1425-4b73-b8fa-ac50d64d29e7"
      },
      {
        name: "Ethereum",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2Fethereum.png?alt=media&token=f5fa362b-b40d-4a8e-8e94-d5c339ed32fa"
      },
      {
        name: "Polygon",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FPolygon.jpg?alt=media&token=39490d08-9fe3-4b7c-b044-2be5e7e05e95"
      },
      {
        name: "Truffle",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FTruffle.png?alt=media&token=47dc2bf7-b11a-476e-aca0-3e3ef2bebd04"
      },
      {
        name: "Chainlink",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2Fchainink.png?alt=media&token=dff35276-f465-4850-b50d-3a6e6d4af155"
      },
     
    ],
  },
  {
    title: "Programming Languages",
    skills: [
      {
        name: "C",
        image:
          "https://w7.pngwing.com/pngs/625/435/png-transparent-the-c-programming-language-computer-programming-logo-language-miscellaneous-text-computer-thumbnail.png",
      },
      {
        name: "C++",
        image:
          "https://w7.pngwing.com/pngs/46/626/png-transparent-c-logo-the-c-programming-language-computer-icons-computer-programming-source-code-programming-miscellaneous-template-blue.png",
      },
      {
        name: "C#",
        image:
        "https://firebasestorage.googleapis.com/v0/b/naveen-portfolio-images.appspot.com/o/Icons%2Ficons8-c-programming-96.png?alt=media&token=a4a06d48-8480-423a-b89e-6b12061e972b&_gl=1*1313x60*_ga*ODM3NjUxNDgyLjE2OTg4MjU1NTY.*_ga_CW55HF8NVT*MTY5ODgyOTQzNi4yLjEuMTY5ODgzNDQ3OC4zMi4wLjA.",
      },
      {
        name: "Solidity",
        image:
        "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FSolidity.jpg?alt=media&token=f4eba7da-832d-47eb-b222-459c69ef7666",
      },
      {
        name: "GO",
        image:
        "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2Fgo.jpg?alt=media&token=3c1ced4b-19bb-49d0-a22b-93919a0013de",
      },
      {
        name: "Java",
        image:
          "https://firebasestorage.googleapis.com/v0/b/naveen-portfolio-images.appspot.com/o/Icons%2Fjava.png?alt=media&token=e0182a9e-51fb-4f1f-87b0-3c2bbdf0e019&_gl=1*z5b6p0*_ga*ODM3NjUxNDgyLjE2OTg4MjU1NTY.*_ga_CW55HF8NVT*MTY5ODgyOTQzNi4yLjEuMTY5ODgzNDQ1MC42MC4wLjA.",
      },
      {
        name: "Python",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FPython.png?alt=media&token=e29216ca-8dfc-44bf-9c72-d77f0694249f",
      },
      {
        name: "JavaScript",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2Fjava-script.png?alt=media&token=9b2ffc2d-baa7-42c9-8ff2-4cb7be71b733",
      },

      
      
    ],
  },
 
  {
    title: "DataBase",
    skills: [
      
     
      {
        name: "SQL Server",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvjB4oElpfXYzmzN53z3N5WS21s0PDIBi-WphS6JasQg&s"
      },
      
      {
        name: "MySQL",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FMySQL.png?alt=media&token=517615ee-c0a9-4ff3-bd8f-f0a72d37ea13"
      },
      
      {
        name: "SQL Lite",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FSQLLite.png?alt=media&token=1276f60f-ee31-4949-a4b0-7f2e7efc993f"
      },
      
      

      {
        name: "FireBase",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxFCJFaVrjs3T6nPGcdPP-CqnWgJCRqt5ifkS83DH60w&s",
      },
      
      {
        name: "MongoDB",
        image:
          "https://firebasestorage.googleapis.com/v0/b/naveen-portfolio-images.appspot.com/o/Icons%2Fmangodb.png?alt=media&token=88778da7-e723-49a5-a5bb-275d32041c4b&_gl=1*bit7al*_ga*ODM3NjUxNDgyLjE2OTg4MjU1NTY.*_ga_CW55HF8NVT*MTY5ODgyOTQzNi4yLjEuMTY5ODgzNDM4NS41Ny4wLjA.",
      },
      
      
      
    ],
  },
  {
    title: "DevOps & Other Tools",
    skills: [
      
      {
        name: "Azure Devops",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FAzure.png?alt=media&token=a0a86b5c-18b0-466e-828c-f66e0aa07781",
      },{
        name: "AWS",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FAWS.png?alt=media&token=72ab0e86-73e6-4dd4-b51e-0fee8a6646be",
      },
      {
        name: "FireBase",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxFCJFaVrjs3T6nPGcdPP-CqnWgJCRqt5ifkS83DH60w&s",
      },
      
      {
        name: "Git",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2Fgit.png?alt=media&token=eb0a13c1-76d3-4842-b8a4-c9f077bbd191",
      },
      {
        name: "GitHub",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2Fgithub.png?alt=media&token=76fbbe75-e6cd-4ae2-a1c0-6f4edaad124b",
      },
      
      {
        name: "Postman",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2Fpostman.png?alt=media&token=eb528489-d9ac-4b78-84b1-be1e14365250",
      },
      
     
      {
        name: "IIS Server",
        image:
          "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FServer.jpg?alt=media&token=e35a832f-a124-4b71-9c29-71ac45994ee9",
      },
     
    ],
  },
];

export const experiences = [
  
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FRRD.png?alt=media&token=a0615c4b-4f0d-4997-92cb-cdb581c28bb8",
    role: "Senior Software Engineer",
    company: "R.R. Donnelley",
    date: "Dec 2022 - Till",
    desc: "Leading Full Stack Development in ASP.NET Core and AWS as a Senior Software Engineer. Instrumental in the development and enhancement of a Job Workflow Management System, a fully configurable application covering every aspect from basic text boxes to dynamic controls. Expertise in configuring every element of the application, showcasing a deep understanding of product intricacies and user workflows. Developing dynamic controls to contribute to a highly customizable and adaptive user interface within the Job Workflow Management System. Senior-level contribution, providing leadership in software development and ensuring seamless operation of the product, reflecting a commitment to high-quality deliverables.",
    skills: [
      ".Net Core",
      "SQL Server",
      "AWS",
      "C#",
      "Javascript",
      "jQuery",
      "HTML",
      "CSS",
      "Bootstrap",
      "Agile Methodalogy",
      "Azure DeOps"
    ],
  },


  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FSTS.png?alt=media&token=6514e469-b378-4628-8bd8-56a5308d8f79",
    role: "Junior Software Engineer",
    company: "Shreyas Tech Solutions",
    date: "Sep 2019 - Nov 2022",
    desc: "Led the successful development of an online flight booking application using ASP.NET MVC technology, contributing to its user-friendly and efficient design. Collaborated with cross-functional teams to design and implement multiple projects from conception to deployment, ensuring high-quality deliverables within project timelines. Applied expertise in ASP.NET MVC, C#, and SQL Server to build robust back-end logic, ensuring seamless integration with front-end components for a cohesive user experience. Specialized in implementing secure payment gateway integration within the flight booking application, ensuring seamless and reliable payment processing for customers. Demonstrated versatility by working on diverse projects, showcasing adaptability from online booking tools to Human Resource Management Systems (HRMS).",
    skills: [
      "Asp .Net MVC",
      "Asp .Net",
      "SQL Server",
      "C#",
      "Javascript",
      "jQuery",
      "HTML",
      "CSS",
      "Bootstrap",
    ],
  },


];

export const education = [
  {
    id: 0,
    img: 'https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FBHC.jpeg?alt=media&token=4bef3888-d119-4672-bdb2-53cfb8820666' ,
    school: "Bishop Heber College, Trichy",
    date: "Jun 2015 - Apr 2018",
    grade: "7.1 CGPA",
    desc: "I completed my undergraduate studies at Bishop Herr College with a CGPA of 7.1.",
    degree: "Bachelor of Science - Mathematics",
  },
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FSchool.png?alt=media&token=39a924fe-16d2-40ec-92fc-e6589591f890",
    school: "St. Peter's Hr. School, Viragalur",
    date: "Jun 2014 -Apr 2015 ",
    grade: "81%",
    desc: "I completed my class 12 high school education at St. Peter's Hr. School,Viragalur.",
    degree: "HSC(XII)",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FSchool.png?alt=media&token=39a924fe-16d2-40ec-92fc-e6589591f890",
    school: "St. Peter's Hr. School, Viragalur",
    date: "Jun 2012 - Mar 2013",
    grade: "75.6%",
    desc: "I completed my class 10 education at St. Peter's Hr. School,Viragalur.",
    degree: "SSLC(X)",
  },
];

export const projects = [
  {
    id: 0,
    smalltitle: "Metrics",
    title: "Metrics - Entire corporate job working flow",
    date: "Dec 2022 - Till",
    WorkingRole: "Full Stack Developer",
    ProjectOverview: "Developed a robust Metrics application using cutting-edge technologies such as .NET Core, Web API, AWS, Razor Pages, and SQL Server. This comprehensive solution caters to corporate needs, allowing them to seamlessly create, review, and move jobs to production. The application incorporates a structured workflow encompassing Quality Control (QC) processes and project delivery. Its versatility is highlighted by the extensive configuration options, enabling users to customize every aspect, from text boxes and labels to color schemes. The project includes a rich array of reports and caters to diverse roles within the corporate environment.",
    description:
      "The Metrics application stands as a testament to innovation and efficiency, utilizing a tech stack that includes .NET Core, Web API, AWS, Razor Pages, and SQL Server. Designed with corporate requirements in mind, the application facilitates a streamlined process where jobs can be created, reviewed, and seamlessly moved into production. The workflow extends to encompass Quality Control (QC) procedures, ensuring the highest standards before project delivery. Notably, the application is highly configurable, allowing users to tailor even minute details, such as text boxes, labels, and color schemes, to meet their specific needs. Its adaptability extends to a rich set of reports and accommodates a multitude of roles within the corporate ecosystem, contributing to a seamless and efficient project management experience.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FMetrics.png?alt=media&token=aea9117d-1dd0-48fc-be02-295d669c712b",
    tags: ["AWS",".Net Core", "Core WebAPI","SQL Server", "Azure","Boostrap","HTML","CSS"],
    category: "Full Stack Development",
    github: "",
    webapp: "https://stage-product.rrdmetrics.com/",
  },
  {
    id: 1,
    smalltitle: "Birthcertificate",
    title: "Birth Certificate Implementation on Blockchain",
    date: "Oct 2023 - Dec 2023",
    WorkingRole: "Full stack Developer",
    ProjectOverview: "In an effort to enhance security, transparency, and accessibility, I've developed a groundbreaking solution for birth certifications using blockchain technology. Birth certificates are not only critical documents but also the foundation of various identity processes. With this project, I'm taking the first step towards establishing a more secure and tamper-proof way of managing these vital records.",
    description:
      " Features and Innovations 🌐🔐 Security: Leveraging the blockchain's immutable nature, each birth certificate is cryptographically secured, preventing unauthorized tampering. 🌍 Decentralization: By utilizing blockchain's decentralized architecture, we're enabling secure access and control without relying on a central authority. 🤝 Authorized Access: Implementing a permissioned structure, guardians, owners, and authorized individuals can efficiently manage birth certificates while maintaining privacy. 📈 Scalability: The project lays the foundation for expanding into a comprehensive certification system, paving the way for more secure digital records.",     
      image:
      "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2Fcertificates.png?alt=media&token=2a06787c-9542-4604-bebb-be856a77b97b",
    tags: [
      "Blockchain",
      "Polygon",
      "Solidity",
      "React Js",
      "Truffle",
      "Web3","Github",
    ],
    category: "web app",
    github: "",
    webapp: "birthcertificate.sirancheevi.com",
  },
  {
    id: 2,
    smalltitle: "Sago Token (ERC-20)",
    title: "Sago Token (ERC 20)",
    date: "Jul 2023 - Aug 2023",
    WorkingRole: "Full stack Developer",
    ProjectOverview: "Successfully developed and deployed an ERC20 token utilizing the Truffle framework. This project has been an exhilarating journey into the world of blockchain development, showcasing my mastery of Truffle for secure smart contract creation, thorough testing, and seamless integration with front-end applications.",
    description:
      "In the dynamic realm of blockchain, I have achieved a notable milestone by successfully conceptualizing, developing, and deploying an ERC20 token using the powerful Truffle framework. This endeavor represents more than the creation of a digital asset; it embodies a journey of mastering the intricacies of Truffle for local development. By leveraging its capabilities, I ensured the creation of secure and efficient smart contracts, conducted meticulous testing, and seamlessly integrated the token functionality into front-end applications. This project not only stands as a testament to my technical proficiency but also reflects a deep understanding of the underlying principles of blockchain technology. ",     
      image:
      "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FToekn.jpg?alt=media&token=9dd4048d-e339-4275-96a8-60e317afdf09",
    tags: [
      "Blockchain",
      "Ethereum",
      "Solidity",
      "React Js",
      "Truffle",
      "Web3",
      "GitHub",
    ],
    category: "web app",
    webapp: "https://www.linkedin.com/posts/sirancheevi-palraj-605335156_blockchaindevelopment-truffleframework-erc20token-activity-7069581637243596801-aLOa?utm_source=share&utm_medium=member_desktop",
  },
  {
    id: 3,
    smalltitle: "Online Air Booking Tool",
    title: "Online Air Booking Tool - B2C, B2B and BOA",
    date: "Sep 2019 - May 2022",
    WorkingRole: "Full stack Developer",
    ProjectOverview: "Embarked on the development of a comprehensive online ticket selling platform catering to the travel industry in Dubai and Egypt. This versatile platform seamlessly integrates local airlines, Global Distribution Systems (GDS), and various suppliers into a unified system. With a focus on Air tickets, Bus tickets, Hotel bookings, Visa services, and Insurance options, the platform is designed to serve both individual consumers (B2C) and businesses (B2B). The innovative feature of a single Top-up account simplifies the purchasing process for agents, allowing them to procure tickets across all available services with ease.",
    description:
      "This project unfolds as a revolutionary online ticket selling platform with a broad spectrum of travel services for both consumers and businesses. Operating out of Dubai and Egypt, the platform offers a unified solution for Air tickets, Bus tickets, Hotel reservations, Visa services, and Insurance options. Through seamless integration with local airlines, GDS, and other suppliers, the platform ensures a vast array of options for travelers. The B2C functionality allows individual consumers to explore and purchase various travel services, while the B2B aspect simplifies the process for agents. The unique feature of a single Top-up account streamlines the ticket procurement process for agents, enabling them to efficiently manage and purchase tickets across all available services. This project reflects a commitment to providing a user-friendly, comprehensive, and integrated solution in the travel industry for the Dubai and Egypt markets.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FFlight.png?alt=media&token=d2bff755-8c7d-4e3c-9981-9fba7f8822b0",
    tags: [".Net MVC", "SQL Server", "Web API","jQuery", "Javascript", "Bootstrap", "CSS", "HTML" ,"TFS","Azure Deops"],
    category: "Web Development",
    github: "",
    webapp: "https://roundtrip.in/",
    
  },
  {
    id: 4,
    title: "Social Media Web Application - Sago Bikers",
    smalltitle: "Sago Bikers",
    date: "Jan 2023 - Jul 2023",
    WorkingRole: "Full stack Developer",
    ProjectOverview: "Embarked on the creation of a comprehensive social media platform catering specifically to the vibrant biking community. Leveraging my expertise as a Full-stack Developer, I designed and implemented a robust Web API application utilizing C# (.Net MVC), jQuery, HTML, CSS, Bootstrap, and integrating MSSQL and Firebase Realtime Database. This dynamic platform provides users with a dedicated space to connect, share experiences, and engage in meaningful interactions. From the ability to like, comment, and share posts to one-to-one and group chat functionalities, this project encapsulates the essence of a thriving biking community.",
    description:
      "The development of this social media application for the biking community was driven by a passion for fostering connections within the cycling world. The application, implemented using C# (.Net MVC), ensures a seamless user experience with a responsive and visually appealing interface crafted with HTML, CSS, and Bootstrap. The integration of a robust backend, powered by MSSQL and Firebase Realtime Database, facilitates real-time interactions and data storage. Users can actively engage with posts, expressing their appreciation through likes, comments, and shares. The inclusion of one-to-one and group chat functionalities further enhances the sense of community, providing a platform for bikers to connect, share insights, and build lasting relationships. This project not only showcases technical proficiency but also a dedication to creating digital spaces that resonate with the unique needs and interests of a specific community.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FBikers.png?alt=media&token=a2b1795f-0c41-4f64-93f4-51e822a60beb",
    tags: [".Net MVC", "SQL Server", "FireBase","Web API","jQuery", "Javascript", "Bootstrap", "CSS", "HTML", "GitHub" ],
    category: "Web Development",
    github: "",
    webapp: "https://sagobikers.prakash.vip/",
    
  },
  {
    id: 5,
    smalltitle: "Payment Gateway",
    title: "Payment Gateway with multiple integration",
    date: "Jun 2022 - Nov 2022",
    WorkingRole: "Backend Developer",
    ProjectOverview: "Led a collaborative effort with the payment gateway team and mobile network operators to develop an innovative and secure SDK payment flow. This project involved seamless integration with numerous banks, UPI, and diverse payment gateways, including Razorpay, 2C2P, PayU, etc. The focus was on implementing a secure and streamlined payment flow within the SDK to facilitate top-ups and support transactions across various industries, including airline, hotel, bus, and car bookings.",
    description:
      "In close collaboration with the payment gateway team and mobile network operators, I spearheaded the development of a sophisticated SDK payment flow, prioritizing security and seamlessness. The SDK seamlessly integrates with multiple banks, UPI, and various payment gateways such as Razorpay, 2C2P, PayU, providing users with a diverse range of options for top-ups and transactions. The project's scope extended to support transactions in the travel sector, encompassing airline, hotel, bus, and car bookings. This collaborative effort underscores a commitment to creating a versatile and secure payment infrastructure, enhancing the user experience across diverse industries and payment platforms.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FPG.png?alt=media&token=4f466f7a-8187-4d44-971f-ed57233161dd",
    tags: [".Net Core", "WebAPI","SQL Server", "TFS","GitHub"],
    category: "Backend Development",
    github: "",
    webapp: "https://roundtrip.in/",
    
  },
  {
    id: 6,
    smalltitle: "Payroll",
    title: "Corporate Payroll Management - B2B",
    date: "Sep 2021 - Apr 2022",
    WorkingRole: "Full Stack Developer",
    ProjectOverview: "Introduced a comprehensive Payroll Management System, a business-to-business (B2B) application meticulously built on .NET Core, SQL Server, and GitHub. The system features a master login capable of creating manager logins, each empowered to oversee multiple corporates. Corporates, in turn, manage their employee details and create individual employee logins. The employee login offers a range of functionalities, including the ability to download payslips, apply for leaves, and execute various HRM tasks. This centralized and secure system streamlines payroll processes and employee management within corporate environments.",
    description:
      "The Payroll Management System, crafted using .NET Core, SQL Server, and GitHub, emerges as a sophisticated B2B application catering to the intricacies of employee management. Anchored by a master login, the system allows the creation of manager logins, each serving as a focal point for overseeing multiple corporates. Within each corporate, comprehensive employee management takes place, facilitated by individual employee logins. This empowers employees to seamlessly download payslips, apply for leaves, and perform various HRM functions. The centralized nature of the system enhances data security and accessibility, ensuring a streamlined and efficient payroll process. The collaborative features fostered by this application underline its utility as a robust tool for B2B businesses, optimizing payroll management and HR processes for enhanced organizational efficiency.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2Fpayroll.png?alt=media&token=1c1b731f-5f79-472a-9ed6-cba5398da4c7",
    tags: [".Net Core", "WebAPI","SQL Server", "GitHub","Boostrap","HTML","CSS"],
    category: "Full Stack Development",
    github: "",
    webapp: "https://payroll.bsite.net/",
  },
  {
    id: 7,
    smalltitle: "SDK Development",
    title: "Banking Process .Net SDK Development",
    date: "Aug 2023 - Sep 2023",
    WorkingRole: "Backend Developer",
    ProjectOverview: "Pioneered the development of a cutting-edge .NET SDK tailored for seamless integration with banking operations. This innovative SDK empowers users to add debit cards, initiate money transfers, check balances, and perform various other banking functionalities. The SDK is versatile, accommodating all .NET versions, including 7.0, and is hosted on the live NuGet Package Manager. A testament to its efficiency and reliability, I undertook the sole development of this SDK, from conceptualization to live deployment, ensuring its accessibility to a wide range of developers. The creation of comprehensive documentation further enhances the user experience, establishing it as a go-to solution for banking-related .NET development.",
    description:
      "As the sole developer behind the creation of a robust .NET SDK, I introduced a transformative solution for banking operations, enhancing the accessibility of essential functionalities for developers. This SDK, meticulously developed and hosted on the live NuGet Package Manager, opens avenues for users to seamlessly integrate banking features into their applications. Users can effortlessly add their debit cards, execute secure money transfers, check account balances, and leverage a range of other banking methods. One of the standout features is its compatibility with all .NET versions, ensuring flexibility for developers, including those utilizing version 7.0. The project's comprehensive scope included not only the development and hosting but also the creation of thorough documentation, ensuring a smooth onboarding experience for developers seeking to incorporate sophisticated banking features into their .NET applications. This project reflects a commitment to innovation, expertise in .NET development, and a dedication to enhancing the accessibility of banking functionalities within the developer community.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FSDKs.jpg?alt=media&token=7b3ed4ed-6d0c-4eca-9754-0c6240419764",
    tags: [".Net", "C#", "Nuget Manager","GitHub"],
    category: "SDK Development",
    github: "",
    webapp: "https://www.nuget.org/packages/Card91BusinessSDK",
    
  },
  
  
  {
    id: 7,
    smalltitle: "BusPass",
    title: "TN Goverment Online Busspass Management Systme",
    date: "Jun 2020 - Jan 2021",
    WorkingRole: "Full Stack Developer",
    ProjectOverview: "Introduced a transformative solution with the Bus Pass project, addressing the challenges of long queues and resource constraints associated with obtaining bus passes. This independent initiative leverages a comprehensive web application developed entirely in .NET, backed by WCF service integration. The application offers users the flexibility to acquire bus passes on an hourly, daily, monthly, or yearly basis. Notably, automated notifications are sent at the end of the plan duration, enhancing user experience. The project also seamlessly integrates electronic devices carried by conductors, enabling efficient validation and sign-off processes. Furthermore, the inclusion of a secure payment gateway ensures a streamlined and secure transaction process.",
    description:
      "The Bus Pass project emerges as a user-centric solution to the prevalent challenges of acquiring bus passes, eliminating queues and optimizing resources. This bespoke web application, developed exclusively using .NET and supported by WCF services, empowers users to obtain bus passes with ease. The application offers flexible plans, allowing users to choose passes on an hourly, daily, monthly, or yearly basis. Automated notifications serve as a convenient reminder at the end of each plan, enhancing user engagement. An innovative integration involves electronic devices provided to conductors, equipped to scan and validate bus passes obtained through the application. Conductors receive instant notifications regarding pass validity, and they can digitally sign off on each transaction. The incorporation of a secure payment gateway ensures the entire process, from pass acquisition to payment, is seamless, efficient, and user-friendly. This project represents a successful fusion of technological innovation, user convenience, and operational optimization in the realm of public transportation.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/myportfolio-c4722.appspot.com/o/Icons%2FBuspass.png?alt=media&token=89bfdad2-b812-4243-9255-bdf431225cc1",
    tags: [".Net MVC","WCF", "SQL Server", "GitHub","Boostrap","HTML","CSS"],
    category: "Full Stack Development",
    github: "",
    webapp: "https://stage-product.rrdmetrics.com/",
  },
  

  

 

  // {
  //   id: 3,
  //   title: "Buckoid",
  //   date: "Dec 2021 - Apr 2022",
  //   description:
  //     "App Is Currently In Playstore 100+ Downloads. This Project proposes an “Expense Tracking App”. Keep track of your personal expenses and compare them to your monthly income with the budget planner. It has Google Drive Cloud API for Backup of User Room Database. Made with Kotlin in MVVM Architecture & Live Data.",
  //   image:
  //     "https://camo.githubusercontent.com/fbf405040ae86b5d4a40f24e4ac034982cb8c0e2d850560ba64527997b258be8/68747470733a2f2f666972656261736573746f726167652e676f6f676c65617069732e636f6d2f76302f622f6c6f67696e2d65613565322e61707073706f742e636f6d2f6f2f4255434b4f49442532302831292e706e673f616c743d6d6564696126746f6b656e3d32653735376235372d323964372d346263612d613562322d653164346538313432373435",
  //   tags: ["Kotlin", "MVVM", "Room Database", "Google Drive Cloud API"],
  //   category: "android app",
  //   github: "https://github.com/rishavchanda/Buckoid-Android-App",
  //   webapp: "https://play.google.com/store/apps/details?id=com.rishav.buckoid",
  // },

  // {
  //   id: 10,
  //   title: "Job Finding App",
  //   date: "Jun 2023 - Jul 2023",
  //   description:
  //     "A Job Finding App made with React Native, Axios. Users can search for any job coming from API and apply there.",
  //   image:
  //     "https://user-images.githubusercontent.com/64485885/255237090-cf798a2c-1b41-4bb7-b904-b5353a1f08e8.png",
  //   tags: ["React Native", "JavaScript", "Axios"],
  //   category: "android app",
  //   github: "https://github.com/rishavchanda/Job-finder-App",
  //   webapp: "https://github.com/rishavchanda/Job-finder-App",
  // },

  // {
  //   id: 4,
  //   title: "Whatsapp Clone",
  //   date: "Jul 2021",
  //   description:
  //     "A WhatsApp clone made with React JS, Firebase, and Material UI. It has Phone Authentication, Real-time Database. It has a chat room where users can chat with each other. It has a sidebar where users can see all the chat rooms and can create a new chat room. It has a login page where users can log in with their Google account.",
  //   image:
  //     "https://firebasestorage.googleapis.com/v0/b/whatsapp-clone-rishav.appspot.com/o/Screenshot%20(151).png?alt=media&token=48391593-1ef0-4a8c-a92a-eb82bdf38e89",
  //   tags: ["React Js", "Firebase", "Firestore", "Node JS"],
  //   category: "web app",
  //   github: "https://github.com/rishavchanda/Whatsapp-Clone-React-Js",
  //   webapp: "https://whatsapp-clone-rishav.web.app",
  // },

  // {
  //   id: 5,
  //   title: "Todo Web App",
  //   date: "Jun 2021",
  //   description:
  //     " A Todo Web App made with React JS, Redux, and Material UI. It has a login page where users can log in with their Google account. It has a sidebar where users can see all the tasks and can create a new task. It has a calendar where users can see all the tasks on a particular date. It has a search bar where users can search for a particular task.",
  //   image:
  //     "https://camo.githubusercontent.com/a328255ad96f861f57d25096d28018ab2656c689a1456b0d145764009bed2d1a/68747470733a2f2f666972656261736573746f726167652e676f6f676c65617069732e636f6d2f76302f622f746f646f2d6170702d63386331392e61707073706f742e636f6d2f6f2f53637265656e73686f74253230283938292e706e673f616c743d6d6564696126746f6b656e3d33643335646366322d626666322d343730382d393031632d343232383866383332386633",
  //   tags: ["React Js", "Local Storage", "AWS Auth", "Node JS"],
  //   category: "web app",
  //   github: "https://github.com/rishavchanda/Todo-Web-App",
  //   webapp: "https://rishav-react-todo.netlify.app/",
  // },

  // {
  //   id: 6,
  //   title: "Breaking Bad",
  //   date: "Jun 2021",
  //   description:
  //     "A simple react app that shows the characters of the famous TV series Breaking Bad. It uses the Breaking Bad API to fetch the data. It also has a search bar to search for a particular character.",
  //   image:
  //     "https://camo.githubusercontent.com/cd07010cbeb90cb1b43a5d6162784326aef02210ef7d41a0f9ae043b3e392378/68747470733a2f2f666972656261736573746f726167652e676f6f676c65617069732e636f6d2f76302f622f746f646f2d6170702d63386331392e61707073706f742e636f6d2f6f2f53637265656e73686f7425323028313534292e706e673f616c743d6d6564696126746f6b656e3d65613439383630632d303435362d343333342d616435372d336239346663303333363263",
  //   tags: ["React Js", "API", "Axios", "Node JS"],
  //   category: "web app",
  //   github: "https://github.com/rishavchanda/Breaking-Bad",
  //   webapp: "https://breaking-bad-webapp.netlify.app",
  // },

  // {
  //   id: 7,
  //   title: "Quiz App",
  //   date: "Dec 2020 - Jan 2021",
  //   description:
  //     "A android quiz app made with Java and Firebase. It has a login page where users can log in with their Google account. It has a sidebar where users can see all the quiz categories and can create a new quiz. It has a leaderboard where users can see the top 10 scorers. It has a search bar where users can search for a particular quiz.",
  //   image:
  //     "https://github-production-user-asset-6210df.s3.amazonaws.com/64485885/239726262-c1b061d1-d9d0-42ef-9f1c-0412d14bc4f6.gif",
  //   tags: ["Java", "Android Studio", "Firebase", "Google Auth"],
  //   category: "android app",
  //   github: "https://github.com/rishavchanda/Quiz-Earn",
  //   webapp: "https://github.com/rishavchanda/Quiz-Earn",
  // },

  // {
  //   id: 8,
  //   title: "Face Recognition",
  //   date: "Jan 2021",
  //   description:
  //     "A Face recognition python app made with OpenCV. It uses face_recognition library to detect faces. It uses the webcam to detect faces. It also has a search bar to search for a particular face.",
  //   image:
  //     "https://camo.githubusercontent.com/a8b3e1ebf26d4c95f75bc6671189a3590eb67eb8332d7c7452492990e731efb9/68747470733a2f2f77617265686f7573652d63616d6f2e696e67726573732e636d68312e707366686f737465642e6f72672f356137383335396561323762643261633232336437656662306639303831306437373930383436312f363837343734373037333361326632663633366336663735363432653637363937343638373536323735373336353732363336663665373436353665373432653633366636643266363137333733363537343733326633383339333633363339333232663332333433343333333033333339333832663333333636363330363533333636333032643331333336333632326433313331363533373264333833323335333832643334363433303633333936333635333136353334333133393265363736393636",
  //   tags: ["Python", "Keras", "TensorFlow", "VGG16", "Pickle", "React"],
  //   category: "machine learning",
  //   github: "https://github.com/rishavchanda/Face-Recodnition-AI-with-Python",
  //   webapp: "https://github.com/rishavchanda/Face-Recodnition-AI-with-Python",
  // },
];


export const TimeLineData = [
  { year: 2020, text: "Started my journey" },
  { year: 2021, text: "Started my a B.E CSE journey" },
  { year: 2021, text: "Founded JavaScript Mastery" },
  { year: 2022, text: "Shared my projects with the world" },
  { year: 2022, text: "Started my own platform" },
];
